<template>
  <div>
    <b-row v-if="!planningCenterSettings.is_connected">
      <b-col sm="auto">
        <b-card title="Planning Center Integration">
          <div class="d-flex align-items-center mt-2 mb-1">
            <b-button
              variant="primary"
              :href="authorizationUrl"
            >Connect with your Planning Center account
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="12">
        <b-row>
          <b-col md="auto">
            <b-card>
              <b-card-header class="p-0">
                <h4 class="mb-3">
                  Plan Your Visit is connected with your Planning Center account
                </h4>
                <div class="mx-1 d-none d-md-block" />
                <b-card-text class="mb-3 d-inline-flex align-items-center">
                  <label
                    for="activate_integration"
                    class="ml-auto mb-0 cursor-pointer"
                  >Activate Integration</label>
                  <b-form-checkbox
                    id="activate_integration"
                    v-model="planningCenterSettings.is_active"
                    switch
                    class="ml-1"
                    :disabled="isSubmitting"
                    @change="updatePlanningCenterSettings"
                  />
                </b-card-text>
              </b-card-header>
              <div class="h5 pt-1 mb-2">
                Add new contact to Planning Center:
              </div>
              <b-form-group>
                <b-form-radio-group
                  v-model="planningCenterSettings.add_contacts_on"
                  class="integration-settings"
                  :options="addContactsOnOptions"
                  name="radios-stacked"
                  stacked
                  :disabled="isSubmitting"
                  @change="updatePlanningCenterSettings"
                />
              </b-form-group>
              <template v-if="!showWorkflowListLoader">
                <template v-if="addToWorkflowOptions && addToWorkflowOptions.length > 1">
                  <div class="h5 pt-1 mb-2">
                    ...and add to workflow:
                  </div>
                  <b-form-group class="v-select--formgroup">
                    <v-select
                      v-model="planningCenterSelectedValue"
                      :options="addToWorkflowOptions"
                      :placeholder="planningCenterSettings.add_to_workflow_id === null ? planningCenterSelectDefaultValue.text : ''"
                      label="text"
                      class="w-auto"
                      @input="setPlanningCenterSelectedValue"
                    />
                  </b-form-group>
                </template>
                <template v-else>
                  There are no workflows in your PlanningCenter account.
                </template>
              </template>
              <template v-else>
                <b-spinner
                  label="Fetching workflows"
                />
              </template>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <hr class="mt-50">
            <b-button
              variant="outline-danger"
              :disabled="isSubmitting"
              @click="disconnectPlanningCenterAccount"
            >
              Disconnect your account
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BRow,
  BFormRadioGroup,
  BFormGroup,
  BCardHeader,
  BFormCheckbox,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { cloneDeep } from 'lodash'
import store from '@/store'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BCardText,
    BFormRadioGroup,
    BFormGroup,
    BCardHeader,
    BFormCheckbox,
    BFormSelect,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      authorizationUrl: process.env.VUE_APP_PLANNING_CENTER_AUTH_URL,
      isSubmitting: false,
      planningCenterSettings: null,
      showWorkflowListLoader: true,
      addContactsOnOptions: [
        { text: 'After first visit', value: 'first_visit' },
        { text: 'Immediately after scheduling a visit', value: 'signup' },
      ],
      addToWorkflowOptions: null,
      planningCenterSelectedValue: null,
      planningCenterSelectDefaultValue: {
        value: null,
        text: "Don't add to any workflow",
      },
    }
  },
  created() {
    this.loadSettings()
    this.refreshWorkflowsList()
  },
  methods: {
    loadSettings() {
      this.planningCenterSettings = cloneDeep(store.getters['auth/account'].planning_center_integration)
      this.planningCenterSettings.is_active = !!this.planningCenterSettings.is_active
    },
    setPlanningCenterSelectedValue(value) {
      if (value === null) {
        this.planningCenterSelectedValue = this.planningCenterSelectDefaultValue
      } else {
        this.planningCenterSelectedValue = value
      }
      this.updatePlanningCenterSettings()
    },
    async updatePlanningCenterSettings() {
      const self = this
      try {
        self.isSubmitting = true
        await AccountService.updatePlanningCenterIntegrationSettings(
          {
            is_active: self.planningCenterSettings.is_active,
            add_contacts_on: self.planningCenterSettings.add_contacts_on,
            add_to_workflow_id: self.planningCenterSelectedValue ? self.planningCenterSelectedValue.value : null,
          },
        ).then(response => {
          store.dispatch('auth/setAuthUser', response.data.data).then(() => { self.loadSettings() })
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: 'Item updated successfully',
              variant: 'success',
            },
          })
        })
      } finally {
        self.isSubmitting = false
      }
    },
    refreshWorkflowsList() {
      const self = this
      self.showWorkflowListLoader = true

      AccountService.getPlanningCenterWorkflows()
        .then(apiResponse => {
          try {
            self.addToWorkflowOptions = [this.planningCenterSelectDefaultValue]
            let isSelectedOptionInWorkflowList = false
            Object.values(apiResponse.data.data)
              .forEach(workflow => {
                self.addToWorkflowOptions.push({
                  value: workflow.id,
                  text: workflow.attributes.name,
                })
                if (workflow.id === this.planningCenterSettings.add_to_workflow_id) {
                  isSelectedOptionInWorkflowList = true
                  self.planningCenterSelectedValue = [{
                    value: workflow.id,
                    text: workflow.attributes.name,
                  }]
                }
              })
            // if the selected workflow is not on the list anymore, set default
            if (!isSelectedOptionInWorkflowList) {
              this.planningCenterSettings.add_to_workflow_id = null
            }
            self.showWorkflowListLoader = false
          } finally {
            self.showWorkflowListLoader = false
          }
        }).catch(error => {
          // refresh token expired, we need to redirect user to PC to login again to get a new one
          if (error.response.data.error === 'RefreshTokenExpired') {
            window.location = this.authorizationUrl
          }
        })
    },
    disconnectPlanningCenterAccount() {
      const self = this
      try {
        this.isSubmitting = true
        AccountService.disconnectPlanningCenterIntegration().then(response => {
          store.dispatch('auth/setAuthUser', response.data.data).then(() => {
            this.loadSettings()
            this.refreshWorkflowsList()
          })

          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: 'Your account has been disconnected',
              variant: 'success',
            },
          })
        })
      } finally {
        this.isSubmitting = false
      }
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-checkbox .custom-control-label, .custom-radio .custom-control-label{
  cursor:pointer;
}
.integration-settings > .custom-radio {
  margin-bottom: 1rem;
}
</style>
<style scoped>
  .v-select--formgroup{
    width:50%;
  }
  @media screen and (max-width: 768px){
    .v-select--formgroup{
      width:100%;
    }
  }
</style>
